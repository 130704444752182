import { useEffect } from 'react';
import { useTenant } from '~/tenants/common/TenantContextProvider';
import { TenantId } from '~/tenants/common/registry';
import { useUser } from '~/components/hooks/useUser';

interface UserGuidingArgs {
  company_name?: string;
  company_id?: string;
  order_id_submitted?: string;
  order_id_delivered?: string;
}

export function useUserGuiding() {
  const tenant = useTenant();

  if (tenant.id !== TenantId.TWIST_TOURS) {
    return false;
  }

  useEffect(() => {
    // @ts-ignore
    if (window['userGuidingLayer']) {
      return;
    }

    // @ts-ignore
    window['userGuidingLayer'] = [];
    const f = document.getElementsByTagName('script')[0];
    const k = document.createElement('script');
    k.async = true;
    k.src = 'https://static.userguiding.com/media/user-guiding-C5Y968508OEID-embedded.js';
    f.parentNode?.insertBefore(k, f);
    // @ts-ignore
    if (window['userGuiding']) return;
    // @ts-ignore
    const ug = (window['userGuiding'] = { q: [] }) as any;
    ug.c = function (n: unknown) {
      return function () {
        ug.q.push([n, arguments]);
      };
    };
    const m = ['previewGuide', 'finishPreview', 'track', 'identify', 'hideChecklist', 'launchChecklist'];
    for (let j = 0; j < m.length; j += 1) {
      ug[m[j]] = ug.c(m[j]);
    }
  }, []);

  return true;
}

export default function useAuthenticatedUserGuiding(args: UserGuidingArgs): void {
  const enabled = useUserGuiding();
  const user = useUser();

  useEffect(() => {
    if (!enabled) {
      return;
    }

    // @ts-ignore
    window['userGuiding'].identify(user.id, {
      email: user.email,
      name: user.name,
      order_id_submitted: args.order_id_submitted,
      order_id_delivered: args.order_id_delivered,
      customer_id: args.company_id,
      company:
        args.company_name && args.company_id
          ? {
              id: args.company_id,
              name: args.company_name,
            }
          : undefined,
    });
  }, [user, args]);
}
